<template>
  <section id="the-arts">
    <div id="the-arts-description">
      <p>
        We started our company rooted in professional theatre and the live arts
        and have been delivering expertise in a variety of productions for over
        twenty years.
      </p>
    </div>
    <div id="gallery">
      <TheArtsItem
        v-for="production in sortedProductions"
        :poster="production.poster"
        :key="production.date"
        :title="production.title"
        :venue="production.venue"
        :jobs="production.jobs"
        :date="production.date"
        :endDate="production.enddate">
      </TheArtsItem>
    </div>
  </section>
</template>
<script>
import TheArtsItem from '@/components/What We Do/TheArtsItem.vue'

import { gsap } from 'gsap/all'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
import { TextPlugin } from 'gsap/TextPlugin'
import '../assets/scripts/blur'
import VanillaTilt from 'vanilla-tilt'

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CSSRulePlugin, TextPlugin)

export default {
  data() {
    return {
      productions: [
        {
          title: 'Black Adder Goes Forth',
          venue: 'Viaduct Theatre, Halifax',
          date: '01/11/2019',
          enddate: '',
          jobs: ['Lighting Design', 'Stage Management', 'Sound Design'],
          poster: 'black-adder-goes-forth-2018.jpg',
        },
        {
          title: 'This Story of Yours',
          venue: 'The Marlborough Theatre, Brighton',
          date: '01/10/2018',
          enddate: '',
          jobs: [
            'Sound Design',
            'Casting',
            'Direction',
            'Lighting Design',
            'Set Design',
            'Stage Management',
            'Production Management',
          ],
          poster: 'this-story-of-yours.jpg',
        },
        {
          title: 'Kissing Frogs',
          venue: 'UK Tour',
          date: '16/06/2016',
          enddate: '16/02/2019',
          jobs: [
            'Stage Management',
            'Lighting Design',
            'Sound Design',
            'Voice Over',
          ],
          poster: 'kissing-frogs.jpg',
        },
        {
          title: 'Ghost - Drama Workshop and Performance',
          venue: 'Preston Manor, Brighton',
          date: '01/08/2014',
          enddate: '',
          jobs: [
            'Direction',
            'Script Writing',
            'Sound Design',
            'Costume Hire',
            'Stage Management',
          ],
          poster: 'ghost-and-drama-workshop-2014.jpg',
        },
        {
          title: 'Privates on Parade',
          venue: 'Theatre Royal, Brighton',
          date: '01/08/2013',
          enddate: '01/09/2013',
          jobs: ['Cast', 'Production Management', 'Audio/Video Design'],
          poster: 'privates-on-parade.jpg',
        },
        {
          title: 'Victory',
          venue: 'The Haymarket Theatre, Basingstoke',
          date: '24/03/2011',
          enddate: '',
          jobs: [
            'Production Design',
            'Stage Management',
            'Video Design',
            'Sound Design',
          ],
          poster: 'victory.jpg',
        },
        {
          title: 'Oh! What a Lovely War!',
          venue: 'The Haymarket Theatre, Basingstoke',
          date: '14-10-2010',
          enddate: '',
          jobs: ['Stage Management', 'Lighting Design', 'Sound Design'],
          poster: 'oh-what-a-lovely-war-2010.jpg',
        },
        {
          title: 'Robin Hood',
          venue: 'The Thameside Theatre, Grays',
          date: '03/12/2010',
          enddate: '09/01/2011',
          jobs: ['Cast'],
          poster: 'robin-hood.jpg',
        },
        {
          title: 'Cinderella',
          venue: 'The Beck Theatre, Hayes',
          date: '12/12/2009',
          enddate: '03/01/2010',
          jobs: ['Stage Management', 'Company Management'],
          poster: 'cinderella-2009.jpg',
        },
        {
          title: 'Aladdin',
          venue: "Southmill Arts Centre, Bishop's Stortford",
          date: '11/12/2009',
          enddate: '02/01/2010',
          jobs: ['Cast'],
          poster: 'aladdin-2009.jpg',
        },
        {
          title: 'Stage Struck - 200 Years of The Theatre Royal',
          venue: 'Brighton Museum and Art Gallary, Brighton',
          date: '05/05/2007',
          enddate: '02/09/2007',
          jobs: ['Production Management', 'Presention', 'Direction'],
          poster: 'stagestruck-200-years-of-the-theatre-royal-brighton.jpg',
        },
        {
          title: 'Ajjaj 2020',
          venue: 'Centrepoint Theatre, Dubai',
          date: '14/08/2007',
          enddate: '22/08/2007',
          jobs: [
            'Direction',
            'Stage Management',
            'Audio Design',
            'A/V Editing',
            'Narration',
            'Fight Direction',
          ],
          poster: 'ajjaj-the-living-sandstorm-2007.jpg',
        },
        {
          title: 'Oliver The Musical',
          venue: 'Madinat Theatre, Dubai',
          date: '01/06/2008',
          enddate: '',
          jobs: ['Direction', 'Set Design', 'Lighting Design', 'Cast'],
          poster: 'oliver-the-musical-2008.jpg',
        },
        {
          title: 'The Adventures of Sinbad',
          venue: 'Centrepoint Theatre, Dubai',
          date: '01/06/2007',
          enddate: '',
          jobs: [
            'Scripting',
            'Direction',
            'Set Design',
            'Musical Direction',
            'Lighting Design',
            'Choreography',
            'Casting',
            'Wardrobe Design',
          ],
          poster: 'the-adventures-of-sindbad-2007.jpg',
        },
        {
          title: 'Ali Baba and the Forty Thieves',
          venue: 'Centrepoint Theatre, Dubai',
          date: '01/02/2007',
          enddate: '',
          jobs: [
            'Scripting',
            'Direction',
            'Set Design',
            'Musical Direction',
            'Lighting Design',
            'Casting',
            'Wardrobe Design',
          ],
          poster: 'ali-baba-and-the-fourty-thieves.jpg',
        },
        {
          title: 'The Railway Children',
          venue: 'UK Tour',
          date: '01/07/2007',
          enddate: '04/08/2007',
          jobs: ['Stage Management', 'Cast', 'Lighting Design'],
          poster: 'the-railway-children-2007.jpg',
        },
        {
          title: 'Little Women',
          venue: 'UK Tour',
          date: '01/10/2007',
          enddate: '03/11/2007',
          jobs: ['Stage Management', 'Lighting Design'],
          poster: 'little-women-2007.jpg',
        },
        {
          title: 'Turn of The Screw',
          venue: 'UK Tour',
          date: '06/02/2008',
          enddate: '24/06/2008',
          jobs: ['Stage Management', 'Cast', 'Lighting Design', 'Sound Design'],
          poster: 'the-turn-of-the-screw-2008.jpg',
        },
        {
          title: 'Guys and Dolls',
          venue: 'The New Connaught Theatre, Worthing',
          date: '01/11/2006',
          enddate: '',
          jobs: [
            'Direction',
            'Vocal Technique',
            'Lighting Design',
            'Set Design',
            'Casting',
          ],
          poster: 'guys-and-dolls-2006.jpg',
        },
        {
          title: 'Aladdin and his Magic Lamp',
          venue: 'Madinat Theatre, Dubai',
          date: '01/12/2005',
          enddate: '',
          jobs: [
            'Direction',
            'Scripting',
            'Set Design',
            'Music Production',
            'Wardrobe Design',
            'Casting',
            'Fight Direction',
            'Lighting Design',
            'Stage Management',
          ],
          poster: 'aladdin-and-his-magic-lamp-2005.jpg',
        },
        {
          title: 'The Talented Mr Ripley',
          venue: 'Brighton Dome & Studio Theatre/Brighton Little Theatre',
          date: '01/06/2001',
          enddate: '01/07/2001',
          jobs: ['Cast', 'Fight Direction'],
          poster: 'the-talented-mr-ripley-2001.jpg',
        },
        {
          title: 'Mother Goose',
          venue: 'The New Connaught Theatre, Worthing',
          date: '01/12/2002',
          enddate: '01/01/2003',
          jobs: [
            'Production Management',
            'Cast',
            'Stage Management',
            'Company Management',
          ],
          poster: 'mother-goose-2002.jpg',
        },
        {
          title: 'Jack and the Beanstalk',
          venue: 'The New Connaught Theatre, Worthing',
          date: '01/12/2001',
          enddate: '01/01/2002',
          jobs: ['Cast', 'Production Management', 'Company Management'],
          poster: 'jack-and-the-beanstalk-2001.jpg',
        },
        {
          title: 'Dick Whittington',
          venue: 'The New Connaught Theatre, Worthing',
          date: '01/12/2000',
          enddate: '01/01/2001',
          jobs: [
            'Production Management',
            'Company Management',
            'Stage Management',
            'Cast',
          ],
          poster: 'dick-whittington-2000.jpg',
        },
        {
          title: 'Cinderella',
          venue: 'The New Connaught Theatre, Worthing',
          date: '01/12/1999',
          enddate: '01/01/2000',
          jobs: ['Production Management', 'Company Management'],
          poster: 'cinderella-1999.jpg',
        },
        {
          title: 'The Company of Wolves',
          venue: 'UK Tour',
          date: '01/03/1998',
          enddate: '01/11/1998',
          jobs: ['Cast', 'Stage Management'],
          poster: 'the-company-of-wolves-1998.jpg',
        },
        {
          title: 'Revenge',
          venue: 'UK Tour',
          date: '01/08/1997',
          enddate: '01/10/1997',
          jobs: ['Cast', 'Stage Management'],
          poster: 'revenge-1997.jpg',
        },
        {
          title: 'Winnie The Pooh',
          venue: 'UK Tour',
          date: '01/03/1996',
          enddate: '01/11/1996',
          jobs: ['Cast', 'Stage Management'],
          poster: 'winnie-the-pooh-1996.jpg',
        },
        {
          title: 'Peter Pan',
          venue: 'The New Connaught Theatre, Worthing',
          date: '13/12/1996',
          enddate: '12/01/1997',
          jobs: ['Cast', 'Stage Management'],
          poster: 'peter-pan-1996.jpg',
        },
        {
          title: 'A Christmas Carol',
          venue: 'UK Tour',
          date: '01/12/1991',
          enddate: '01/12/1992',
          jobs: ['Cast', 'Stage Management'],
          poster: 'a-christmas-carol-1992.jpg',
        },
      ],
    }
  },
  computed: {
    /** @returns { array } */
    sortedProductions() {
      let sortedProductions = this.productions.slice(0).sort(function (a, b) {
        let c = new Date(a.date)
        let d = new Date(b.date)
        return d - c
      })
      return sortedProductions
    },
  },
  components: { TheArtsItem },
  activated() {
    gsap.fromTo(
      '#the-arts-description',
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.4, delay: 1 }
    )
    const theArtsItems = gsap.utils.toArray('.the-arts-item')

    // Animate each portfolio item on appear.

    theArtsItems.forEach((item) => {
      item.tl = gsap
        .timeline({
          paused: true,
          delay: 0.3,
          onStart: () => {
            VanillaTilt.init(item, {
              max: 3,
              speed: 200,
              perspective: 400,
              glare: true,
              'max-glare': 0.2,
              gyroscope: false,
              scale: 1.1,
            })
          },
          callbackScope: item,
          onReverseComplete: function () {
            // If the portfilio item disappesars remove the tilt effect.
            if (this.vanillaTilt) {
              this.vanillaTilt.destroy()
            }
          },
        })
        .fromTo(
          // Scale BG div from bottom left to top right.
          item.querySelector('.the-arts-item-mis-bg'),
          {
            alpha: 1,
            scale: 0,
          },
          {
            scale: 1,
            transformOrigin: 'bottom left',
            stagger: 0.2,
            duration: 0.3,
            delay: 0.7,
            rotation: 0.001,
            force3D: true,
          },
          '0'
        )
        .set(item.querySelector('.the-arts-item-mis-fg'), {
          alpha: 1,
        })
        .to(
          [item.querySelector('.the-arts-item-background')],
          {
            alpha: 1,
            duration: 0,
          },
          '+=0'
        )
        .fromTo(
          item.querySelector('.the-arts-item-mis-fg'),
          { x: '-=70%', y: '-=-50%' },
          {
            duration: 0.9,
            stagger: 0.2,
            scaleY: 0,
            force3D: true,
          },
          '+=0.0'
        )
        .set(
          [
            item.querySelector('.the-arts-item-mis-fg'),
            item.querySelector('.the-arts-item-mis-bg'),
          ],
          {
            alpha: 0,
            duration: 0,
          },
          '+=0.0'
        )
        .to(
          item,
          {
            overflow: 'visible',
            duration: 0,
          },
          '+=0.0'
        )
        .to(item.parentElement.querySelector('.item-description'), {
          alpha: 1,
          duration: 0,
        })

        // Fade in the item description list.
        .fromTo(
          item.parentElement.querySelector('#production-jobs').children,
          {
            alpha: 0,
          },
          {
            alpha: 1,
            stagger: 0.05,
          }
        )
        .to(
          item.querySelector('.the-arts-item-background-blur'),
          {
            opacity: 0.8,
            duration: 0.2,
            stagger: 0.2,
          },
          '-=0.5'
        )
    })
    ScrollTrigger.batch('.the-arts-item', {
      batchMax: () => {
        try {
          let galleryWidth = document.getElementById('gallery').offsetWidth
          let portfolioItemWidth =
            document.querySelector('.arts-item-wrapper').offsetWidth
          let numberOfItemsToShow = Math.floor(
            galleryWidth / portfolioItemWidth
          )
          if (numberOfItemsToShow !== null) {
            return numberOfItemsToShow
          } else {
            return 1
          }
        } catch {
          return 1
        }
      },
      interval: 0.9,
      onEnter: this.showPortfolioItem,
      onLeaveBack: this.hidePortfolioItem,
      start: 'center bottom',
      end: 'bottom -50%',
      markers: false,
    })
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 800px)': function () {
        // setup animations and ScrollTriggers for screens 800px wide or greater (desktop) here...
        // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
        let oddTl = gsap.timeline({
          scrollTrigger: {
            trigger: '#gallery',
            id: 'slideOddArtsItems',
            // start: "top bottom", // the default values
            // end: "bottom top",
            scrub: 2,
          },
        })
        oddTl.to('#gallery > div:nth-child(odd)', {
          force3D: true,
          yPercent: 10,
          ease: 'none',
        })

        let evenTl = gsap.timeline({
          scrollTrigger: {
            trigger: '#gallery',
            id: 'slideOddArtsItems',
            // start: "top bottom", // the default values
            // end: "bottom top",
            scrub: 2,
          },
        })
        evenTl.to('#gallery > div:nth-child(even)', {
          force3D: true,
          yPercent: -20,
          ease: 'none',
        })
      },

      // mobile
      '(max-width: 799px)': function () {
        // The ScrollTriggers created inside these functions are segregated and get
        // reverted/killed when the media query doesn't match anymore.
      },

      // all
      all: function () {
        // ScrollTriggers created here aren't associated with a particular media query,
        // so they persist.
      },
    })
    ScrollTrigger.refresh(true)
  },
  deactivated() {
    const killTimeline = (timeline) => {
      const targets = timeline.getChildren()

      timeline.kill()

      for (let i = 0; i < targets.length; i++) {
        if (targets[i].targets().length) {
          gsap.set(targets[i].targets(), { clearProps: 'all' })
        }
      }
    }

    const theArtsItems = gsap.utils.toArray('.the-arts-item')

    theArtsItems.forEach((item) => {
      gsap.delayedCall(2, () => killTimeline(item.tl))
    })
    ScrollTrigger.refresh(true)
  },
  methods: {
    showPortfolioItem(batch) {
      batch.forEach(function (target) {
        target.tl.play()
      })
    },

    hidePortfolioItem(batch) {
      batch.forEach(function (target, i) {
        gsap.delayedCall(i * 0.05, function () {
          target.tl.reverse()
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.statement {
  font-size: 1.6vh;
  float: left;
  clear: both;
  position: relative;
  margin: 0 auto;
  width: 50%;
  text-align: center;
  color: rgb(204, 204, 204);
}
#the-arts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  margin: 10vh auto;
}

#the-arts-description {
  color: rgb(238, 238, 238);
  font-size: 2vmin;
  text-align: center;
  margin: 0 auto;
  padding-top: 1.3vmin;
  width: 80%;
  @media #{$mq-tiny} {
    font-size: 3.7vmin;
  }
}

#gallery {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; /* align vertical */
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 auto;
  padding-left: 0;
  top: 3vh;
  height: 100%;

  @media #{$mq-tiny} {
    width: 100%;
    margin-left: 0%;
    padding-left: 0vh;
    top: 4vh;
  }

  @media #{$mq-small} {
    width: 100%;
    top: 2vh;
  }

  @media #{$mq-medium} {
    width: 90%;
  }

  @media #{$mq-large} {
    width: 80%;
  }

  @media #{$mq-xlarge} {
    width: 70%;
  }

  @media #{$mq-xxlarge} {
    width: 65%;
    justify-content: space-between;
    top: 5vh;
  }

  /* Portrait */
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    width: 100%;
  }

  /* Landscape */
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    width: 100%;
  }
  @media all and (width: 428px) and (height: 926px) and (orientation: landscape) {
    width: 10%;
  }
}
#gallery > div:nth-child(2n + 1) {
  top: 0px;
  position: relative;
}

#gallery > div:nth-child(2n) {
  top: 0px;

  @media #{$mq-small} {
    top: 70px;
  }
  @media #{$mq-medium} {
    top: 150px;
  }

  @media #{$mq-large} {
    top: 150px;
  }

  @media #{$mq-xlarge} {
    top: 150px;
  }

  @media #{$mq-xxlarge} {
    top: 150px;
  }
}

// #gallery:after {
//   content: '';
//   flex: auto;
// }

#gallery > div {
  margin-bottom: calc(100vh - 90vh);
  position: relative;
}
</style>
